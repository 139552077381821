<template>
    <b-taglist v-if="debug" attached class="environment">
        <b-tag type="is-danger">DEV</b-tag>
    </b-taglist>
</template>

<script>
export default {
	name: 'Environment',
	data() {
		return {
			debug: false
		}
	},
	mounted() {
        this.debug = process.env.VUE_APP_BASE_DEBUG == 'true'
    }
}
</script>
